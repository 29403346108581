import React from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as TikTokIcon } from "../icons/tiktok.svg";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../icons/youtube.svg";
import { ReactComponent as FacebookIcon } from "../icons/facebook.svg";
import { ReactComponent as WhatsappIcon } from "../icons/watsup.svg";
import { ReactComponent as MailIcon } from "../icons/mail.svg";
import { ReactComponent as PatreonIcon } from "../icons/patriom.svg";

const sidebarAppearKeyframes = keyframes`
	from {
		transform: translateY(-500px);
		opacity: 1;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
`;

const sidebarAppearAnimation = css`
  animation-name: ${sidebarAppearKeyframes};
  animation-duration: 500ms;
  animation-delay: 500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

const HeaderIcons = styled.ul`
  width: 60px;
  padding-left: 15px;
  z-index: 3;
  position: fixed;
  top: 115px;
  opacity: 0;
  ${({ isLoaded }) => isLoaded && sidebarAppearAnimation}

  @media (max-width: 812px) {
    top: 100px;
  }

  a {
    margin-bottom: 16px;
    display: inline-block;
    height: 38px;
  }

  a svg {
    transition: all 0.2s;
  }

  a:active {
    opacity: 0.8;
  }

  a:hover svg {
    transform: scale(1.2);
  }
`;

const SideMenu = props => {
  return (
    <HeaderIcons {...props}>
      <a
        href="https://www.patreon.com/yevhenmoskalov"
        target="_blank"
        rel="noreferrer"
      >
        <PatreonIcon />
      </a>
      <a
        href="https://www.tiktok.com/@useyourfingerss?_t=8Xf0wqAeVXi&_r=1"
        target="_blank"
        rel="noreferrer"
      >
        <TikTokIcon />
      </a>
      <a
        href="https://www.instagram.com/yevhenmoskalov"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://www.youtube.com/@yevhenmoskalov"
        target="_blank"
        rel="noreferrer"
      >
        <YoutubeIcon />
      </a>
      <a
        href="https://www.facebook.com/yevhenmoskalov"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon />
      </a>
      <a href="https://wa.me/447939413504" target="_blank" rel="noreferrer">
        <WhatsappIcon />
      </a>
      <a href="mailto:yevmoskalov@gmail.com" target="_blank" rel="noreferrer">
        <MailIcon />
      </a>
    </HeaderIcons>
  );
};

export default SideMenu;

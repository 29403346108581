import React from "react";
import styled from "styled-components";
import airbnbBg from "../images/virtual-show.jpg";
import Button from "../components/button";

const Wrapper = styled.div`
  background-color: black;
  background-image: url("${airbnbBg}");
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  padding: 56px 60px;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

	@media (max-width: 812px) {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), url("${airbnbBg}");

    background-size: cover;
    background-position: 20% top;
    height: 100%;
    min-height: 768px;
    padding: 20px 60px;
    padding-right: 20px;
	}
`;

const AirbnbTitle = styled.h3`
  font-weight: 600;
  font-size: 64px;
  text-align: left;
  margin: 0;

  @media (max-width: 812px) {
    font-size: 28px;
    padding: 0;
    text-align: center;
  }
`;

const AirbnbDescription = styled.p`
  margin-top: 20px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 812px) {
    align-items: center;
    font-size: 18px;
    background: black;
    flex-direction: row;
    padding-left: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 0;
  margin-top: 40px;

  @media (min-width: 812px) {
    margin: 40px 70px;
  }
`;

const AirBnb = () => {
  return (
    <Wrapper>
      <AirbnbTitle>VIRTUAL SHOW</AirbnbTitle>

      <AirbnbDescription>
        <div>
          Book Yev's highly interactive virtual show for an immersive experience
          of real magic from the comfort of your home. Elevate the experience
          with an exclusive masterclass, where you'll learn the basics of card
          magic
        </div>
        <StyledButton href="mailto:yevmoskalov@gmail.com">
          BOOK NOW
        </StyledButton>
      </AirbnbDescription>
    </Wrapper>
  );
};

export default AirBnb;

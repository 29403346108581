import styled from "styled-components";

const Button = styled.a`
  cursor: pointer;
  color: inherit;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 8px 16px;
  text-transform: uppercase;
  border: 1px solid white;
  transition: 0.3s all;
  min-width: 200px;

  font-weight: 600;

  &:hover {
    background-image: linear-gradient(#ff385c, #ff385c);
  }

  &:active {
    transform: scale(95%);
  }

  @media (min-width: 812px) {
    margin: 40px 70px;

    height: 60px;
    font-size: 20px;
  }
`;

export default Button;
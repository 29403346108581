import React, { useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import ControlledYoutube from "./controlledYoutube";
import useMediaQuery from "../hooks/useMediaQuery";
import { ReactComponent as PlayIcon } from "../icons/play.svg";

const Background = styled.div`
  min-height: 100vh;
  height: 100%;

  @media (max-width: 812px) {
    background-attachment: scroll;
    background-position: center top 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  max-width: 50%;
  min-width: 225px;
  align-items: flex-end;
  justify-content: center;
  margin-top: -210px;

  @media (max-width: 812px) {
    margin-top: -100px;
    max-width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
  }
`;

const HeaderDescription = styled.div`
  justify-content: center;
  height: 210px;
  width: 300px;
  padding-right: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-align: right;
  border-right: 2px solid #ffffff;
  position: relative;
  transition: transform 0.3s;
  transform: ${({ isShown }) =>
    isShown ? "translateY(0px)" : "translateY(210px)"};

  @media (max-width: 812px) {
    height: 100px;
  }
`;

const StyledPlayIcon = styled(PlayIcon)`
  position: absolute;
  right: 0px;
  bottom: 120px;
  height: 80px;
  width: 80px;
  cursor: pointer;

  @media (min-width: 812px) {
    position: absolute;
    right: -250px;
  }
`;

const headerCss = css`
  height: 100vh;
  min-height: 700px;
  transition: opacity 0.3s;
  position: absolute;
  top: 0;
`;

const HeaderYoutube = styled(ControlledYoutube)`
  ${headerCss}
`;

const VideoTag = styled.video`
  min-height: 100%;
  object-fit: cover;
  width: 100%;
`;

const Video = ({ className, src, play, onLoad }) => {
  const getVideo = () => {
    const video = document.getElementById("video");
    if (!video) throw new Error("no video element");
    return video;
  };

  const handleClick = () => {
    const video = getVideo();
    video.paused ? video.play() : video.pause();
  };

  useEffect(() => {
    const video = getVideo();
    play ? video.play() : video.pause();
  }, [play]);

  useEffect(() => {
    getVideo().addEventListener("loadedmetadata", () => {
      onLoad();
    });

    return () => getVideo().removeEventListener("loadedmetadata", onLoad);
  }, []);

  return (
    <div className={className}>
      <VideoTag
        playsInline={true}
        controls={false}
        preload="auto"
        onClick={handleClick}
        id="video"
        src={src}
      />
    </div>
  );
};

const HeaderVideo = styled(Video)`
  ${headerCss}
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledImage = styled(GatsbyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};

  :after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.5;
  }
`;

const Header = ({ isLoaded, className, onLoad, ...props }) => {
  const [count, setCount] = useState(0);
  const [play, setPlay] = useState(false);
  const [player, setPlayer] = useState(null);
  const isVertical = useMediaQuery("(max-width: 812px)");

  const handleClick = useCallback(() => {
    if (!isLoaded) return;

    setCount(c => c + 1);
    setPlay(p => !p);
  }, [isLoaded]);

  const handleEnd = () => {
    setPlay(false);
    setCount(c => c + 1);
  };

  useEffect(() => {
    if (!player || !isLoaded) return;
    if (play) {
      player.playVideo();
    } else {
      player.pauseVideo();
    }
  }, [play, player, isLoaded]);

  const showPlayButton = isLoaded && !play;

  return (
    <div>
      <Background className={className} onClick={() => {
        if (play) handleClick();
      }}>
        {isVertical ? (
          <HeaderVideo
            play={play}
            src="/video/video.webm"
            onLoad={onLoad}
            setPlay={setPlay}
          />
        ) : (
          <HeaderYoutube
            onEnd={handleEnd}
            videoId="YOncAtmsWbE"
            id="youtube_player"
            onPlayerReady={onLoad}
            setPlayer={setPlayer}
            player={player}
          />
        )}
        <StyledImage
          {...props}
          isShown={!play && count === 0}
          alt="Header image"
          imgStyle={{
            objectPosition: "54% top",
            background:
              "linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
          }}
        />
      </Background>
      <HeaderContainer>
        <HeaderDescription isShown={showPlayButton} play={play}>
          Magician
          <StyledPlayIcon onClick={handleClick} />
        </HeaderDescription>
      </HeaderContainer>
    </div>
  );
};

export default Header;

import { useState, useEffect } from "react";

/**
 * useMediaQuery hook
 * @param {string} query - Media query string.
 * @returns {boolean} - True if the document matches the media query, false otherwise.
 */
export default function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Create MediaQueryList object
    const media = window.matchMedia(query);

    // Initial check
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    // Event listener for changes
    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener("change", listener);

    // Cleanup
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

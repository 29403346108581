import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Normalize } from "styled-normalize";
import Header from "../sections/header";
import About from "../sections/about";
import Services from "../sections/services";
import Youtube from "../sections/youtube";
import Airbnb from "../sections/airbnb";
import Gallery from "../sections/gallery";
import Footer from "../sections/footer";
import Seo from "../components/seo.js";
import SideMenu from "../components/side-menu.js";
import { graphql } from "gatsby";
import SiteTitle from "../components/site-title";

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100vw;
    overflow-x: hidden;
  }
  html {
    box-sizing: border-box;
		min-width: 320px;
    height: 1px;
    min-height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ul {
    padding-left: 0;
  }

  a {
    text-decoration: none;
  }
  p {
    margin: 0;
  }

  body {
    background-color: black;
    font-family: 'Montserrat', sans-serif;
    color: white;
    min-height: 100vh;
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
`;

const ContentWithoutNavbar = styled.div`
  padding-left: 75px;
  overflow: hidden;
  background-color: black;
  position: relative;
`;

const IndexPage = ({ data: { zhekan } }) => {
  const [isBackgroundLoaded, setBackgroundLoad] = useState(false);
  return (
    <Seo
      title="Yev Magician"
      description="Yev is an incredibly talented magician with a lifelong passion
      for sleight of hand and mind reading. From his early days in
      Ukraine to the vibrant streets of London, his global sleight of
      hand mastery leaves jaws dropping"
      keywords={[
        "sleight of hand",
        "cardistry",
        "magic",
        "magician",
        "card magician",
        "card magic",
        "street magic",
        "card tricks",
        "playing cards",
        "playing cards magic",
        "playing cards trick",
        "ukrainemagic",
        "kyivmagic",
        "cardtricks online",
        "cardmagic online",
      ]}
    >
      <GlobalStyle />
      <Normalize />
      <Header
        isLoaded={isBackgroundLoaded}
        image={zhekan.childImageSharp.gatsbyImageData}
        onLoad={() => setBackgroundLoad(true)}
      />
      <SiteTitle isLoaded={isBackgroundLoaded} />
      <SideMenu isLoaded={isBackgroundLoaded} />
      <About />
      <Services />
      <Airbnb />
      <ContentWithoutNavbar>
        <Youtube videoSrc="https://www.youtube.com/embed/HkNDHI8VCYI?si=b5F4V0--4CVQQED8" />
      </ContentWithoutNavbar>
      <Footer />
    </Seo>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    zhekan: file(relativePath: { eq: "header-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, height: 1080, layout: FULL_WIDTH)
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import Button from "../components/button";

const FooterContainer = styled.footer`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
`;

const Container = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 62px;

  a {
    color: white;
    text-transform: initial;
  }
`;

const Location = styled.address`
  font-style: normal;
  font-weight: bold;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Button as="a" href="mailto:yevmoskalov@gmail.com">
        Contact
      </Button>

      <Container>
        <div>
        </div>
      </Container>
    </FooterContainer>
  );
};

export default Footer;

import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Lightbox from "react-modal-image";

const Images = styled.div`
  min-height: 100vh;
  background-color: black;
  width: 100%;
  display: grid;
  padding: 80px 20px 40px 62px;
  grid-gap: 40px; /* Space between grid items */

  @media (min-width: 812px) {
    grid-template-columns: auto auto; /* Define two columns */
    padding: 100px 62px 60px;
  }
`;

const PartySlider = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, height: 1080, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);
  const images = data.allFile.nodes;


  return (
    <>
      <Images>
        {images.map((item, key) => {
          return (
            <Lightbox
              key={key}
              small={item.publicURL}
              medium={item.publicURL}
              large={item.publicURL}
              onClose={() => setOpen(false)}
            />
          );
        })}
      </Images>
    </>
  );
};

export default PartySlider;

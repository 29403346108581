import React from "react";
import styled from "styled-components";
import Slider from "../components/slider";
import zhekanWithCard from "../images/about/slider-2-min.jpg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  min-height: 100vh;
  position: relative;
  background: black;

	@media (max-width: 812px) {
		display: block;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%), url("${zhekanWithCard}");
		background-size: contain;
		background-position: top center;
		background-repeat: no-repeat;
	}
`;

const Section = styled.section`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 812px) {
    align-items: flex-end;
    margin-right: 10px;
  }
`;

const Title = styled.h2`
  max-width: 300px;
  font-style: normal;
  font-size: 82px;
  font-weight: 600;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: right;
  margin-top: 0;

  p {
    font-size: 110px;
  }

  @media (max-width: 812px) {
    margin-top: 40px;
    font-weight: 500;
  }
`;

const Description = styled.div`
  background: black;
  margin-left: 75px;
  margin-right: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  position: relative;
  max-width: 275px;

  p {
    padding-bottom: 20px;
  }
`;

const TitleContainer = styled.div`
  @media (max-width: 812px) {
    height: 600px;
  }
`;

const VerticalLine = styled.div`
  height: 100px;
  border-right: 2px solid white;

  @media (max-width: 812px) {
    height: 300px;
  }
`;

const StyledImg = styled.img`
  margin-top: 40px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const About = () => (
  <Wrapper>
    <Slider />
    <Section>
      <TitleContainer>
        <VerticalLine />
        <Title>Who is Yev?</Title>
      </TitleContainer>
      <Description>
        <p>
          Yev is an incredibly talented magician with a lifelong passion for
          sleight of hand and mind reading. From his early days in Ukraine to
          the vibrant streets of London, his global sleight of hand mastery
          leaves jaws dropping.
        </p>
        <p>
          Yev's magic knows no bounds, captivating audiences worldwide in bars,
          restaurants, theatres, and on the streets.
        </p>
      </Description>
      <StyledImg width="191" height="191" src="/images/magic-circle.png" />
    </Section>
  </Wrapper>
);

export default About;

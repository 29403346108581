/* eslint-disable no-undef */
import React, { useEffect } from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const ControlledYoutube = ({
  videoId,
  className,
  onEnd,
  onPlayerReady,
  setPlayer,
}) => {
  const onYouTubeIframeAPIReady = () => {
    const newPlayer = new window.YT.Player("player", {
      height: "1920",
      width: "1080",
      videoId: videoId,
      playerVars: {
        start: 0,
        autoplay: 0, // Start manually
        controls: 0, // Hide controls
        enablejsapi: 1, // Enable the JavaScript API
        iv_load_policy: 3,
        rel: 0, // Do not show related videos at the end
        showinfo: 0, // Do not show video info before playing
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: event => {
          if (event.data === YT.PlayerState.PLAYING) {
            console.log("Video is playing");
          } else if (event.data === YT.PlayerState.PAUSED) {
            console.log("Video is paused");
          } else if (event.data === YT.PlayerState.ENDED) {
            newPlayer.seekTo(0);
            console.log("Video has ended");
            // Here, invoke any function you want to run when the video ends
            onEnd();
          }
        },
      },
    });
    setPlayer(newPlayer);
  };

  // Load the YouTube IFrame Player API asynchronously
  useEffect(() => {
    // This function creates an <iframe> (and YouTube player)
    // after the API code downloads.

    // Set global function for YouTube API to call when ready
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

    return () => {
      // Cleanup the script when the component unmounts
      window.onYouTubeIframeAPIReady = null;
    };
  }, [videoId]);

  useEffect(() => {
    // Load YouTube API script
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }, []);

  return (
    <VideoWrapper className={className}>
      <div id="player" />
    </VideoWrapper>
  );
};

export default ControlledYoutube;

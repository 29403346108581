import React from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  margin-top: 50px;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: -10px;
    width: calc(100% + 20px);
    height: 100%;
  }
`;

const Youtube = ({ videoSrc, id, className }) => {
  return (
    <VideoWrapper className={className}>
      <iframe
        width="1220"
        height="693"
        display="flex"
        src={videoSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        id={id}
      />
    </VideoWrapper>
  );
};

export default Youtube;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { animated, useTransition } from "react-spring";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

const SliderContainer = styled.div`
  flex-basis: 50%;
  position: relative;

  @media (max-width: 812px) {
    display: none;
  }
`;

const ImageWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ControlContainer = styled.form`
  position: absolute;
  left: -20px;
  width: 10px;
  height: 50px;
`;

const ControlPositioner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ControlCheckbox = styled.div`
  input {
    display: none;
  }

  label {
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid white;
    width: 10px;
    height: 10px;
    display: block;
  }

  input:checked + label {
    border-radius: 10px;
    background-color: white;
  }
`;

const Slider = () => {
  const data = useStaticQuery(graphql`
    query SliderQuery {
      allFile(filter: { relativeDirectory: { eq: "about" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              transformOptions: { cropFocus: NORTH }
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  `);
  const images = data.allFile.nodes.map(
    img => img.childImageSharp.gatsbyImageData
  );

  const [currentIndex, setIndex] = useState(0);
  const timerRef = useRef(null);

  const transitions = useTransition(images[currentIndex], item => getSrc(item), {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 },
  });

  const startTimer = () => {
    timerRef.current = setInterval(
      () => setIndex(index => (index === 0 ? 1 : 0)),
      5000
    );
  };

  const endTimer = () => {
    clearInterval(timerRef.current);
  };

  useEffect(() => {
    startTimer();
    return endTimer;
  }, []);

  const handleClick = index => {
    setIndex(index);
    endTimer();
    startTimer();
  };

  return (
    <SliderContainer>
      <ControlPositioner>
        <ControlContainer>
          {images.map((image, index) => (
            <ControlCheckbox key={getSrc(image)}>
              <input
                name="same"
                id={getSrc(image)}
                value={index}
                type="radio"
                checked={currentIndex === index}
                onChange={() => handleClick(index)}
              />
              <label htmlFor={getSrc(image)} />
            </ControlCheckbox>
          ))}
        </ControlContainer>
      </ControlPositioner>
      {transitions.map(({ item, props, key }) => {
        return (
          <ImageWrapper key={key} style={props}>
            <GatsbyImage
              image={item}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              imgStyle={{
                objectPosition: "center center",
                objectFit: "cover",
              }}
            />
          </ImageWrapper>
        );
      })}
    </SliderContainer>
  );
};

export default Slider;

import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: black;
  margin-left: 70px;
  margin-right: 20px;
  margin-bottom: 100px;

  @media (min-width: 812px) {
    padding: 100px 0;
    margin: 0 70px;
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 220px;
  margin-bottom: 60px;
  align-items: center;

  @media (max-width: 812px) {
    flex-direction: column;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  & > * {
    flex: 1 1 50%;
  }
`;

const TextContainer = styled.div`
  h2 {
    text-align: center;
  }
  @media (max-width: 812px) {
    margin-top: 20px;
  }

  @media (min-width: 812px) {
    padding-left: 95px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;

  @media (max-width: 812px) {
    min-height: 200px;
  }
`;

const SERVICES_CONTENT = [
  {
    title: "Walk around",
    description:
      "Immerse your guests in a  personal experience with close up magic at your corporate party or birthday celebration. Let the magic unfold as Yev brings enchantment directly to your guests, creating unforgettable moments that make your event truly magical",
  },
  {
    title: "Stage show",
    description:
      "Step into an exciting world with our magic show on stage. Yev's charming tricks and amazing surprises will catch everyone's attention. Watch as Yev performs on stage, creating a magical experience that will captivate your audience.",
  },
  {
    title: "Workshop",
    description:
      "From private classes to international lectures. Discover a spectrum of card techniques from Yev's 20 years of expertise. Whether you're a beginner or a seasoned pro, this workshop is designed to elevate your mastery of card sleights",
  },
];

const Service = ({ img, children }) => {
  return (
    <ServiceContainer data-check={img.src}>
      <StyledImage
        image={img}
        imgStyle={{
          objectPosition: "center top",
          objectFit: "contain",
        }}
      />
      <TextContainer>{children}</TextContainer>
    </ServiceContainer>
  );
};

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServiceQuery {
      allFile(
        filter: { relativeDirectory: { eq: "services" } }
        sort: { fields: relativePath, order: ASC }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              transformOptions: { cropFocus: NORTH }
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  `);
  const images = data.allFile.nodes.map(
    img => img.childImageSharp.gatsbyImageData
  );
  return (
    <Wrapper>
      {images.map((img, i) => {
        const { title, description } = SERVICES_CONTENT[i];
        return (
          <Service img={img} key={img.src}>
            <h2>{title}</h2>
            <p>{description}</p>
          </Service>
        );
      })}
    </Wrapper>
  );
};

export default Services;

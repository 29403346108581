import React from "react";
import styled, { keyframes, css } from "styled-components";

const TITLE = "Yev";

const appear = keyframes`
  from {
    transform: translateX(-500px);
		opacity: 1;
  }

  to {
		transform: translateX(0px);
		opacity: 1;
  }
`;

const appearAnimation = css`
  animation-name: ${appear};
  animation-duration: 500ms;
  animation-delay: 700ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

const HeaderTitle = styled.h1`
  left: 13px;
  font-weight: bold;
  font-size: 54px;
  position: absolute;
  top: 60px;
  margin: 0;
  z-index: 10;

  @media (max-width: 812px) {
    top: 15px;
    font-size: 56px;
  }

  display: flex;
  flex-direction: row;

  opacity: 0;
  ${({ isLoaded }) => isLoaded && appearAnimation}
`;

const SiteTitle = props => {
  return <HeaderTitle {...props}>{TITLE}</HeaderTitle>;
};

export default SiteTitle;
